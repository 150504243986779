.app{
    background-color: black;
    color: white;
    height: 100dvh;
    width: 100%;
    text-align: center;
    align-content: center;
}

.app h1{
    font-size: 4rem;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    padding-bottom: 10vh;
}

::selection{
    background-color: cadetblue;
    color: brown;
}