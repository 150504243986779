*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;

}

::selection{
  background: rgb(254, 87, 26);
  color: white;
}